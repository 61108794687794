/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  // {
  //   url: "/apps/email",
  //   name: "Email",
  //   slug: "email",
  //   icon: "MailIcon",
  //   i18n: "Email",
  // },
  {
    url: "/dashboard",
    name: "Dashboard",
    slug: "dashboard",
    tagColor: "warning",
    icon: "HomeIcon",
    i18n: "Dashboard",
  },
  {
    header: "Primary Menu",
    icon: "PackageIcon",
    i18n: "PrimaryMenus",
    items: [
      {
        url: null,
        name: "Administration",
        icon: "PackageIcon",
        i18n: "Administration",
        submenu: [
          {
            url: "/incoming-letter",
            name: "IncomingLetter",
            slug: "incoming-letter",
            i18n: "IncomingLetter",
          },
          {
            url: "/outgoing-letter",
            name: "OutGoingLetter",
            slug: "outgoing-letter",
            i18n: "OutGoingLetter",
          },
          {
            url: "/invoice-customer",
            name: "InvoiceCustomer",
            slug: "invoice-customer",
            icon: "FileTextIcon",
            i18n: "InvoiceCustomer",
          },
          {
            url: "/proforma-invoice",
            name: "ProformaInvoice",
            slug: "proforma-invoice",
            icon: "FileIcon",
            i18n: "ProformaInvoice",
          },
          {
            url: "/purchase-order-customer",
            name: "PurchaseOrderCustomer",
            slug: "purchase-order-customer",
            icon: "ShoppingCartIcon",
            i18n: "PurchaseOrderCustomer",
          },
          {
            url: "/purchase-to-principle",
            name: "PurchaseToPrinciple",
            slug: "purchase-to-principle",
            icon: "ShoppingCartIcon",
            i18n: "PurchaseToPrinciple",
          },
          {
            url: "/customer",
            name: "Customer",
            slug: "customer",
            icon: "UsersIcon",
            i18n: "Customer",
          },
          {
            url: "/request-driver",
            name: "RequestDriver",
            slug: "request-driver",
            icon: "PhoneOutgoingIcon",
            i18n: "RequestDriver",
          },
          {
            url: "/request-messenger",
            name: "RequestMessenger",
            slug: "request-messenger",
            icon: "CornerUpRightIcon",
            i18n: "RequestMessenger",
          },
          {
            url: "/reminder",
            name: "Reminder",
            slug: "reminder",
            icon: "CheckCircleIcon",
            i18n: "Reminder",
          },
        ],
      },
      {
        url: null,
        name: "Technical",
        icon: "ToolIcon",
        i18n: "Technical",
        submenu: [
          // {
          //   url: '/technical/techteam-report',
          //   name: 'TechTeamReport',
          //   slug: 'techteam-report',
          //   i18n: 'TechTeamReport',
          // },
          // {
          //   url: '/technical/helpdesk-schedule',
          //   name: 'HelpdeskSchedule',
          //   slug: 'helpdesk-schedule',
          //   i18n: 'HelpdeskSchedule',
          // },
          {
            url: "/technical/learning-center",
            name: "LearningCenter",
            slug: "learning-center",
            i18n: "LearningCenter",
            submenu: [
              {
                url: "/technical/learning-center/category",
                name: "Category",
                slug: "learning-center-category",
                i18n: "Category",
              },
              {
                url: "/technical/learning-center/knowledge-base",
                name: "Knowledge Base",
                slug: "knowledge-base",
                i18n: "KnowledgeBase",
              },
              {
                url: "/technical/learning-center/issue-tracking",
                name: "IssueTracking",
                slug: "equipment-equipment",
                i18n: "IssueTracking",
              },
            ],
          },
          {
            url: "/technical/equipment",
            name: "Equipment",
            slug: "equipment",
            i18n: "Equipment",
            submenu: [
              // {
              //   url: "/technical/equipment/status",
              //   name: "EquipmentStatus",
              //   slug: "equipment-status",
              //   i18n: "Status",
              // },
              {
                url: "/technical/equipment/brand",
                name: "EquipmentBrand",
                slug: "equipment-brand",
                i18n: "Brand",
              },
              {
                url: "/technical/equipment/equipment",
                name: "EquipmentEquipment",
                slug: "equipment-equipment",
                i18n: "Equipment",
              },
              // {
              //   url: "/technical/equipment/delivered",
              //   name: "EquipmentDelivered",
              //   slug: "equipment-delivered",
              //   i18n: "Delivered",
              // },
            ],
          },
          {
            url: "/technical/sparepart",
            name: "EquipmentSparepart",
            slug: "technical-sparepart",
            i18n: "Sparepart",
            submenu: [
              {
                url: "/technical/sparepart/manage",
                name: "EquipmentSparepareManage",
                slug: "sparepart-manage",
                i18n: "Categories",
              },
              {
                url: "/technical/sparepart/report",
                name: "EquipmentSparepartReport",
                slug: "sparepart-report",
                i18n: "Sparepart",
              },
              // {
              //   url: "/technical/sparepart/clu",
              //   name: "EquipmentSparepartCLU",
              //   slug: "sparepart-clu",
              //   i18n: "CLU",
              //   submenu: [
              //     {
              //       url: "/technical/sparepart/clu/add",
              //       name: "AddCLU",
              //       slug: "add-clu",
              //       i18n: "AddCLU",
              //     },
              //     {
              //       url: "/technical/sparepart/clu/in",
              //       name: "CLUInItem",
              //       slug: "clu-in-item",
              //       i18n: "In",
              //     },
              //     {
              //       url: "/technical/sparepart/clu/out",
              //       name: "CLUOutItem",
              //       slug: "clu-out-item",
              //       i18n: "Out",
              //     },
              //   ],
              // },
              // {
              //   url: "/technical/sparepart/handphone",
              //   name: "EquipmentSparepartHandphone",
              //   slug: "sparepart-handphone",
              //   i18n: "Handphone",
              //   submenu: [
              //     {
              //       url: "/technical/sparepart/handphone/add",
              //       name: "AddSparepartHandphone",
              //       slug: "sparepart-handphone-add",
              //       i18n: "AddHandphone",
              //     },
              //     {
              //       url: "/technical/sparepart/handphone/in",
              //       name: "HandphoneIncomeItem",
              //       slug: "sparepart-handphone-in",
              //       i18n: "In",
              //     },
              //     {
              //       url: "/technical/sparepart/handphone/out",
              //       name: "HandphoneLeaveItem",
              //       slug: "sparepart-handphone-out",
              //       i18n: "Out",
              //     },
              //   ],
              // },
              // {
              //   url: "/technical/sparepart/hdd",
              //   name: "EquipmentSparepartHDD",
              //   slug: "sparepart-hdd",
              //   i18n: "HDD",
              //   submenu: [
              //     {
              //       url: "/technical/sparepart/hdd/add",
              //       name: "AddSparepartHDD",
              //       slug: "sparepart-hdd-add",
              //       i18n: "AddHDD",
              //     },
              //     {
              //       url: "/technical/sparepart/hdd/in",
              //       name: "HDDIncomeItem",
              //       slug: "sparepart-hdd-in",
              //       i18n: "In",
              //     },
              //     {
              //       url: "/technical/sparepart/hdd/out",
              //       name: "HDDLeaveItem",
              //       slug: "sparepart-hdd-out",
              //       i18n: "Out",
              //     },
              //   ],
              // },
              // {
              //   url: "/technical/sparepart/interface",
              //   name: "EquipmentSparepartInterface",
              //   slug: "sparepart-interface",
              //   i18n: "Interface",
              //   submenu: [
              //     {
              //       url: "/technical/sparepart/interface/add",
              //       name: "AddSparepartInterface",
              //       slug: "sparepart-interface-add",
              //       i18n: "AddInterface",
              //     },
              //     {
              //       url: "/technical/sparepart/interface/in",
              //       name: "InterfaceIncomeItem",
              //       slug: "sparepart-interface-in",
              //       i18n: "In",
              //     },
              //     {
              //       url: "/technical/sparepart/interface/out",
              //       name: "InterfaceLeaveItem",
              //       slug: "sparepart-interface-out",
              //       i18n: "Out",
              //     },
              //   ],
              // },
              // {
              //   url: "/technical/sparepart/power-supply",
              //   name: "EquipmentSparepartPowerSupply",
              //   slug: "sparepart-power-supply",
              //   i18n: "PowerSupply",
              //   submenu: [
              //     {
              //       url: "/technical/sparepart/power-supply/add",
              //       name: "AddSparepartPowerSupply",
              //       slug: "sparepart-power-supply-add",
              //       i18n: "AddPowerSupply",
              //     },
              //     {
              //       url: "/technical/sparepart/power-supply/in",
              //       name: "PowerSupplyIncomeItem",
              //       slug: "sparepart-power-supply-in",
              //       i18n: "In",
              //     },
              //     {
              //       url: "/technical/sparepart/power-supply/out",
              //       name: "PowerSupplyLeaveItem",
              //       slug: "sparepart-power-supply-out",
              //       i18n: "Out",
              //     },
              //   ],
              // },
              // {
              //   url: "/technical/sparepart/simboard",
              //   name: "EquipmentSparepartSimboard",
              //   slug: "sparepart-simboard",
              //   i18n: "Simboard",
              //   submenu: [
              //     {
              //       url: "/technical/sparepart/simboard/add",
              //       name: "AddSparepartSimboard",
              //       slug: "sparepart-simboard-add",
              //       i18n: "AddSimboard",
              //     },
              //     {
              //       url: "/technical/sparepart/simboard/in",
              //       name: "SimboardIncomeItem",
              //       slug: "sparepart-simboard-in",
              //       i18n: "In",
              //     },
              //     {
              //       url: "/technical/sparepart/simboard/out",
              //       name: "SimboardLeaveItem",
              //       slug: "sparepart-simboard-out",
              //       i18n: "Out",
              //     },
              //   ],
              // },
            ],
          },
          // {
          //   url: "/technical/monthly-report",
          //   name: "MonthlyReport",
          //   slug: "monthly-report",
          //   i18n: "MonthlyReport",
          //   submenu: [
          //     {
          //       url: "/technical/monthly-report/hutchison-3",
          //       name: "Hutchison3",
          //       slug: "hutchison-3",
          //       i18n: "Hutchison3",
          //     },
          //     // {
          //     //   url: "/technical/monthly-report/indosat",
          //     //   name: "Indosat",
          //     //   slug: "indosat",
          //     //   i18n: "Indosat",
          //     // },
          //   ],
          // },
        ],
      },
      {
        url: null,
        name: "Sales",
        icon: "BriefcaseIcon",
        i18n: "Sales",
        submenu: [
          {
            url: "/sales/dashboard",
            name: "SalesDashboard",
            slug: "sales-dashboard",
            i18n: "Dashboard",
          },
          {
            url: "/sales/forecast",
            name: "Forecast",
            slug: "forecast",
            i18n: "Forecast",
          },
          {
            url: "/sales/customer-contact",
            name: "CustomerContact",
            slug: "customer-contact",
            i18n: "CustomerContact",
          },
          {
            url: null,
            name: "SalesReport",
            slug: "sales-report",
            i18n: "Report",
            submenu: [
              {
                url: "/sales/report/visit",
                name: "SalesVisitReport",
                slug: "sales-visit-report",
                i18n: "VisitReport",
              },
              {
                url: "/sales/report/mom",
                name: "SalesMomReport",
                slug: "sales-mom-report",
                i18n: "MomReport",
              },
              {
                url: "/sales/report/progress",
                name: "SalesProgressReport",
                slug: "sales-progress-report",
                i18n: "ProgressReport",
              },
            ],
          },
          {
            url: null,
            name: "SalesPO",
            slug: "sales-purchase-order",
            i18n: "PurchaseOrder",
            submenu: [
              {
                url: "/sales/purchase-order/from_forecast",
                name: "SalesPOFromForecast",
                slug: "sales-purchase-order-from-forecast",
                i18n: "FromForecast",
              },
              {
                url: "/sales/purchase-order/not_from_forecast",
                name: "SalesPONotFromForecast",
                slug: "sales-purchase-order-not-from-forecast",
                i18n: "NotFromForecast",
              },
            ],
          },
          // {
          //   url: null,
          //   name: 'SalesAdmin',
          //   slug: 'sales-admin',
          //   i18n: 'Admin',
          //   submenu: [
          //     {
          //       url: '/sales/admin/privileges',
          //       name: 'SalesAdminPrivileges',
          //       slug: 'sales-admin-privileges',
          //       i18n: 'Privileges',
          //     },
          //     {
          //       url: '/sales/admin/accounts',
          //       name: 'SalesAdminAccount',
          //       slug: 'sales-admin-account',
          //       i18n: 'Accounts',
          //     },
          //     {
          //       url: '/sales/admin/merk',
          //       name: 'SalesAdminMerk',
          //       slug: 'sales-admin-merk',
          //       i18n: 'Merk',
          //     },
          //     {
          //       url: '/sales/admin/customer',
          //       name: 'SalesAdminCustomer',
          //       slug: 'sales-admin-customer',
          //       i18n: 'Customer',
          //     },
          //     {
          //       url: '/sales/admin/statistic',
          //       name: 'SalesAdminStatistic',
          //       slug: 'sales-admin-statistic',
          //       i18n: 'Statistic',
          //     },
          //   ]
          // },
          {
            url: "/quotation",
            name: "Quotation",
            slug: "quotation",
            icon: "MessageCircleIcon",
            i18n: "Quotation",
          },
        ],
      },
    ],
  },
  {
    url: "/technical/ticketing",
    name: "TicketingSystem",
    icon: "MessageSquareIcon",
    i18n: "TicketingSystem",
    submenu: [
      {
        url: "/technical/ticketing/dashboard",
        name: "Dashboard",
        slug: "dashboard",
        i18n: "Dashboard",
      },
      {
        url: "/technical/ticketing/trouble-ticket",
        name: "TroubleTicket",
        slug: "trouble-ticket",
        i18n: "TroubleTicket",
        submenu: [
          {
            url: "/technical/ticketing/trouble-ticket/new",
            name: "NewTroubleTicket",
            slug: "new-trouble-ticket",
            i18n: "NewTroubleTicket",
          },
          {
            url: "/technical/ticketing/trouble-ticket/data",
            name: "DataTroubleTicket",
            slug: "data-trouble-ticket",
            i18n: "DataTroubleTicket",
          },
          {
            url: "/technical/ticketing/trouble-ticket/priority",
            name: "PriorityTroubleTicket",
            slug: "priority-trouble-ticket",
            i18n: "PriorityTroubleTicket",
          },
          {
            url: "/technical/ticketing/trouble-ticket/product",
            name: "ProductTroubleTicket",
            slug: "product-trouble-ticket",
            i18n: "ProductTroubleTicket",
          },
        ],
      },
      {
        url: "/technical/ticketing/report",
        name: "Report",
        slug: "report",
        i18n: "Report",
        submenu: [
          {
            url: "/technical/ticketing/report/daily",
            name: "Daily",
            slug: "report-daily",
            i18n: "Daily",
          },
          {
            url: "/technical/ticketing/report/weekly",
            name: "Weekly",
            slug: "report-weekly",
            i18n: "Weekly",
          },
          {
            url: "/technical/ticketing/report/monthly",
            name: "Monthly",
            slug: "report-monthly",
            i18n: "Monthly",
          },
          {
            url: "/technical/ticketing/report/yearly",
            name: "Yearly",
            slug: "report-yearly",
            i18n: "Yearly",
          },
        ],
      },
      // {
      //   url: '/technical/ticketing/configuration',
      //   name: 'Configuration',
      //   slug: 'configuration',
      //   i18n: 'Configuration',
      //   submenu: [
      //     {
      //       url: '/technical/ticketing/configuration/chart-colors',
      //       name: 'ChartColors',
      //       slug: 'color-of-chart',
      //       i18n: 'ChartColors',
      //     }
      //   ]
      // }
    ],
  },

  {
    url: "/todo-list",
    name: "TodoList",
    slug: "todo-list",
    icon: "CheckSquareIcon",
    i18n: "TodoList",
  },
  {
    url: "/view-report",
    name: "ViewReport",
    slug: "view-report",
    icon: "ClipboardIcon",
    i18n: "ViewReport",
  },
  {
    url: "/job-tracking",
    name: "JobTracking",
    slug: "job-tracking",
    icon: "ActivityIcon",
    i18n: "JobTracking",
  },
  {
    url: "/employee",
    name: "Employee",
    slug: "employee",
    icon: "UsersIcon",
    i18n: "EmployeeData",
  },
  {
    header: "Absent",
    icon: "PackageIcon",
    i18n: "Absent",
    name: "Absent",
    items: [
      {
        url: "/absent",
        name: "SeeAbsent",
        slug: "see-absent",
        icon: "CastIcon",
        i18n: "SeeAbsent",
      },
      {
        url: "/absent/report",
        name: "ReportAbsent",
        slug: "report-absent",
        icon: "PieChartIcon",
        i18n: "ReportAbsent",
      },
    ],
  },
];

