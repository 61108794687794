<template>
  <div
    class="the-navbar__user-meta flex items-center"
    v-if="activeUserInfo.employee.name"
  >
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.employee.name }}</p>
      <small>{{ role }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <vs-avatar
          :src="$storageUser + '/' + activeUserInfo.picture"
          size="30px"
          @click="$router.push(url)"
          width="40"
          height="40"
          class="rounded-full shadow-md cursor-pointer block flex-shrink-0"
        />
        <!--        <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="activeUserInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />-->
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary hover:text-white
            "
            @click="
              $router.push({
                name: 'employee-show',
                params: { id: $secure.encrypt(activeUserInfo.nik) },
              })
            "
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="
              flex
              py-2
              px-4
              cursor-pointer
              hover:bg-primary hover:text-white
            "
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import axios from "@/axios";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {};
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    role() {
      try {
        return this.$store.state.AppActiveUser.employee.position.name;
      } catch (e) {
        return "";
      }
    },
  },
  methods: {
    logout() {
      axios.post("auth/logout").then(({ data: res }) => {
        // if user is logged in via auth0
        if (this.$auth.profile) this.$auth.logOut();

        // if user is logged in via firebase
        const firebaseCurrentUser = firebase.auth().currentUser;

        if (firebaseCurrentUser) {
          firebase
            .auth()
            .signOut()
            .then(() => {
              this.$router.push("/login").catch(() => {});
            });
        }
        
        // If JWT login
        if (localStorage.getItem("accessToken")) {
          localStorage.removeItem("accessToken");
        }

        // Change role on logout. Same value as initialRole of acj.js
        this.$acl.change("admin");
        localStorage.removeItem("userInfo");

        this.$vs.notify({
            title: "Logout Succesfully",
            color: "success"
        });

        // This is just for demo Purpose. If user clicks on logout -> redirect
        this.$router.push("/login").catch(() => {});
      })
      .catch(err => {        
        // If JWT login
        if (localStorage.getItem("accessToken")) {
          localStorage.removeItem("accessToken");
        }

        // Change role on logout. Same value as initialRole of acj.js
        this.$acl.change("admin");
        localStorage.removeItem("userInfo");

        this.$vs.notify({
            title: "Logout Succesfully",
            color: "success"
        });

        // This is just for demo Purpose. If user clicks on logout -> redirect
        this.$router.push("/login").catch(() => {});
      })
    },
  },
};
</script>
